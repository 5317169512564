<template>
  <div class="newsPaper">
    <div class="auto_div">
      <div class="row news_content_panel" style="margin-top:0px;">
        <div class="News" style="width:80%; float:left; height:auto">
          <img src="../../assets/image/jsb.jpg" style="width:100%" />
        </div>
        <div class="NewsRight" style="width:20%; overflow:hidden; float:right">
          <div id="NewsList" style="width:98%; height:auto; float:right">
            <ul class="comUl">
              <li style="height:auto" v-for="(item, index) in newsPaperList" :key="index">
                <span class="newsPaperInner" @click="handlePaper(item)" style="float: left;">
                  {{ item.title }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArtList, getArtInfo } from '@/api/common'
export default {
  components: {},
  data() {
    return {
      newsPaperList: [],
      newsitem: ''
    }
  },
  computed: {},
  mounted() {
    var _this = this

    _this.getArtList()
  },
  methods: {
    getArtList() {
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: _this.$route.params.id
      }
      getArtList(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsPaperList = data
        }
      })
    },
    handlePaper(item) {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        entTArticleUuid: item.entTArticleUuid
      }
      getArtInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          // _this.newsitem = data.content
          let content = data.content.split('href="')[1].split('"')[0]
          window.open(content)
          // var win = window.open(content)
          // win.document.title = data.title
          // let test = window.open(content)
          // test.onload = function () {
          //   test.document.title = data.title
          // }
          // window.open(content)
          // document.title = data.title
        }
      })
    }
  }
}
</script>

<style lang="scss">
.newsPaper {
  width: 100%;
  height: 100%;
}
.newsPaper .auto_div {
  margin-right: auto;
  margin-left: auto;
}
.newsPaper .news_content_panel {
  width: auto;
  overflow: hidden;
}
.newsPaper .News {
  margin: 0 auto;
  overflow: hidden;
  background: #ededed;
}
// .newsPaper ul {
//   padding: 0 15px;
//   height: auto;
//   overflow: hidden;
// }
.newsPaper ul {
    padding: 0 15px;
    height: 810px;
    overflow: auto;
}
.newsPaper ul li {
  padding: 4px 0;
  overflow: hidden;
  border-top: solid 1px #f1f1f1;
}
.newsPaper .comUl li a {
  float: left;
  white-space: nowrap;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
  color: #000000;
  font-size: 16px;
}
a,
a:focus,
a:hover {
  text-decoration: none;
}
a {
  font-family: '微软雅黑', 'Microsoft YaHei', sans-serif;
}
.newsPaperInner {
  display: inline-block;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
}
.newsPaperInner:hover {
  color: #0162b3;
}
@media (max-width: 992px) {
  #NewsList {
    width: 100% !important;
  }
  .newsPaper ul {
    padding: 0 5px;
    height: 600px;
  }
  .newsPaperInner {
    font-size: 15px;
    line-height: 26px;
  }
}
@media (max-width: 768px) {
  .News {
    float: none !important;
    width: 100% !important;
  }
  .NewsRight {
    float: none !important;
    width: 96% !important;
    margin: 0 auto !important;
  }
  .newsPaperInner {
    font-size: 14px;
    line-height: 20px;
    padding: 6px 0;
  }
  .newsPaper ul {
    height: auto;
  }
}
</style>
